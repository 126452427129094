import axios from "axios";

class DiscordService {
    async send(message: string, channel: string = 'frontend') {
        try {
            return await axios.post(this.getChannel(channel), { content: message });
        }
        catch(e) {}
    }

    getChannel(channel: string) {
        switch(channel) {
            case 'client-exception':
                return 'https://discord.com/api/webhooks/1316787192466505808/u3I3pzp5cxAsjaP4hvjktW7BxpjhYehVET-kBGzdvgpzVp7O5IUzCsLtJTADeqAQqzzK';

            default:
                return 'https://discord.com/api/webhooks/1225830779687276629/Mh44O0uOeukI8X3hx08l1NvNO97sVQ5L9MzdZgApM8bL05ut7jahlpJKbfc8pPOaMLCu';
        }
    }
}

const discordService = new DiscordService();
export default discordService;