import cookieOptions from "../../utils/cookieOptions";
import {getCookie, setCookie} from "cookies-next";

class CookieConsentService {
    cookieKey: string = 'cookie_consent';

    cookie(): any {
        try {
            const cookie: any = getCookie(this.cookieKey);
            if (!cookie) return false;
            return JSON.parse(cookie);
        }
        catch(e) {
            return this.defaultValues();
        }
    }

    options(): any {
        let expireDate = new Date();
        expireDate.setMonth(expireDate.getMonth() + 6);
        let maxAge: number = 60 * 60 * 24 * 30 * 6;
        return cookieOptions(expireDate, maxAge);
    }

    defaultValues(): any {
        return {closed: false, analytics: true, ads: true};
    }

    defaultCookie(): void {
        setCookie(this.cookieKey, this.defaultValues(), this.options());
    }

    changeValue(key: string, value: boolean): void {
        const cookie: any = this.cookie();
        cookie[key] = value;
        setCookie(this.cookieKey, cookie, this.options());
    }

    loadAnalytics(): void {
        const scriptTag = document.createElement("script");
        scriptTag.defer = true;
        scriptTag.setAttribute("data-cookie-consent", "tracking");
        scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG}&l=dataLayer&cx=c`;
        document.body.appendChild(scriptTag);

        const inlineScriptTag = document.createElement("script");
        inlineScriptTag.setAttribute("data-cookie-consent", "tracking");
        inlineScriptTag.defer = true;
        inlineScriptTag.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GTAG}');
        `;
        document.body.appendChild(inlineScriptTag);
    }

    unloadAnalytics(): void {
        const scriptTags = document.querySelectorAll("script[data-cookie-consent='tracking']");
        scriptTags.forEach((scriptTag) => {
            scriptTag.remove();
        });

        // @ts-ignore
        if (window.dataLayer) {
            // @ts-ignore
            window.dataLayer = [];
        }
    }

    analytics(): boolean {
        try {
            const cookie: any = this.cookie();
            return cookie.analytics;
        }
        catch(e) {
            return true;
        }
    }

    loadTargeting(): void {
        const inlineScriptTag = document.createElement("script");
        inlineScriptTag.defer = true;
        inlineScriptTag.setAttribute("data-cookie-consent", "targeting");
        inlineScriptTag.innerHTML = `
        var _bxq = _bxq || [];
        // enforce HTTPS
        _bxq.push(['setTrackerUrl', 'https://${process.env.NEXT_PUBLIC_BOXALINO_TRACKER_HOST}']);
        _bxq.push(['setAccount', '${process.env.NEXT_PUBLIC_BOXALINO_ACCOUNT}']);
        _bxq.push(['trackPageView']);

        // additional event such as 'trackCategoryView' should be inserted here

        (function(){
            var s = document.createElement('script');
            s.async = true;
            s.src = 'https://${process.env.NEXT_PUBLIC_BOXALINO_TRACKER_HOST}/static/bav2.min.js';
            document.getElementsByTagName('head')[0].appendChild(s);
        })();
        
        (function(){
            var s = document.createElement('script');
            s.async = true;
            s.src = 'https://${process.env.NEXT_PUBLIC_BOXALINO_RTI_HOST}/static/rti.min.js';
            document.getElementsByTagName('head')[0].appendChild(s);
        })();
        `;
        document.body.appendChild(inlineScriptTag);
    }

    unloadTargeting(): void {
        const scriptTags = document.querySelectorAll("script[data-cookie-consent='targeting']");
        scriptTags.forEach((scriptTag) => {
            scriptTag.remove();
        });

        // @ts-ignore
        if (window._bxq) {
            // @ts-ignore
            window._bxq = [];
        }
    }
}

const cookieConsentService = new CookieConsentService();
export default cookieConsentService;
